import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				...fonts.manrope,
				'--layout-max-width': '1440px',
				'--color-white': '#ffffff',
				'--color-black': '#000000',
				'--color-gold': '#B5926D',
				'--color-grey': '#747474',
				'--color-grey-border': '#ECECEC',
				'--color-red': '#920505',
				'--color-grey-light': '#EEEEEE',
			},
			body: {
				...fonts.manrope,
				color: 'var(--color-black)',
				'& .text-white': {
					color: 'var(--color-white)',
				},
				'& .text-black': {
					color: 'var(--color-black)',
				},
				'& .bg-gray': {
					background: 'var(--color-grey-light)',
				},
				'& .title-alternative': {
					...fonts.times,
				},
				'& .cta-primary': {
					textDecoration: 'none',
					display: 'block',
					fontSize: '12px',
					textTransform: 'uppercase',
					fontWeight: 700,
					letterSpacing: '1.2px',
					borderBottom: '1px solid var(--color-black)',
					width: 'max-content',
					color: 'var(--color-black)',
					marginLeft: 'auto',
					marginRight: 'auto',
					padding: '0 4px 8px',
				},
				'& .cta-secondary': {
					textDecoration: 'none',
					display: 'block',
					fontSize: '12px',
					textTransform: 'uppercase',
					fontWeight: 700,
					letterSpacing: '1.2px',
					borderBottom: '1px solid var(--color-white)',
					width: 'max-content',
					color: 'var(--color-white)',
					marginLeft: 'auto',
					marginRight: 'auto',
					padding: '0 4px 8px',
				},
			},
		},
	},
}

export default CssBaseline
