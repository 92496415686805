export const fontSize = 0.75
export const htmlFontSize = 0.75
export const fonts = {
	manrope: {
		fontFamily: 'Manrope',
		fontWeight: 600,
	},
	times: {
		fontFamily: 'Times New Roman',
		fontWeight: 400,
	},
}
