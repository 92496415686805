const BHUpsells = {
	BHUpsells: {
		defaultProps: {
			showTitle: true,
			skeletonProps: { columnGap: 16 },
			sliderProps: {
				breakpoints: {
					xs: {
						spaceBetween: 8,
					},
					md: {
						spaceBetween: 16,
					},
				},
				controlsPosition: 'top-right',
				spaceBetween: 16,
			},
		},
	},
}

export default BHUpsells
