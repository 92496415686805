/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const NotificationsIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19.6 17.9H3.5c-.2 0-.4-.1-.4-.3-.1-.2-.1-.5.1-.6 0 0 2.5-1.9 2.5-8.3 0-1.7.6-3.2 1.7-4.4 1.3-1.4 3.3-2.1 5.1-1.7.2 0 .4.3.4.5s-.3.4-.5.4c-1.6-.3-3.2.3-4.3 1.5-1 1-1.5 2.4-1.5 3.8 0 4.6-1.3 7.1-2.1 8.2h14.1c-.7-.9-1.7-2.7-2-5.8 0-.2.2-.5.4-.5s.5.2.5.4c.5 4.5 2.4 6 2.4 6 .2.1.2.3.2.5-.1.1-.3.3-.5.3z" />
		<path d="M17.8 8.9c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1S21 4 21 5.7s-1.4 3.2-3.2 3.2zm0-5.4c-1.2 0-2.2 1-2.2 2.2 0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2c.1-1.2-.9-2.2-2.2-2.2zM11.5 21.4c-.4 0-.8-.1-1.1-.3-.3-.2-.6-.5-.8-.8-.1-.2-.1-.5.2-.6.2-.1.5-.1.6.2.1.2.3.4.5.5.4.2.9.2 1.3 0 .2-.1.4-.3.5-.5.1-.2.4-.3.6-.2.2.1.3.4.2.6-.2.3-.5.6-.8.8-.4.2-.8.3-1.2.3z" />
	</svg>
))
NotificationsIconSvg.displayName = 'NotificationsIconSvg'

const NotificationsIcon = forwardRef((props, ref) => <Icon component={NotificationsIconSvg} ref={ref} {...props} />)
NotificationsIcon.displayName = 'NotificationsIcon'

NotificationsIcon.defaultProps = {
	...Icon.defaultProps,
}
NotificationsIcon.propTypes = {
	...Icon.propTypes,
}

export default NotificationsIcon
export { NotificationsIconSvg }
