import { fonts } from '../fonts'

const Tooltip = {
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				maxWidth: 'fit-content',
				...fonts.manrope,
				padding: '0',
				borderRadius: 0,
				'&.tooltip-marker': {
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-black)',
					borderRadius: 4,
					boxShadow: `0 0 60px 0 rgba(0,0,0,0.17)`,
					padding: '32px 24px',
					maxWidth: 230,
					margin: 0,
				},
			},
			tooltipArrow: {
				padding: '0',
			},
			tooltipPlacementTop: {
				padding: '0',
			},
		},
	},
}

export default Tooltip
